import Home from '../pages/Home';
import AccountSettings from '../pages/AccountSettings';
import Achievements from '../pages/Achievements';
import LeaderBoards from '../pages/LeaderBoards';
import Login from '../pages/Login';
import Profile from '../pages/Profile';
import Shop from '../pages/Shop';
import PlayLists from '../pages/Playlists';

const routes = [
	{
		url: '/',
		component: <Home />,
	},
	{
		url: '/home',
		component: <Home />,
	},
	{
		url: '/settings',
		component: <AccountSettings />,
	},
	{
		url: '/achievements',
		component: <Achievements />,
	},
	{
		url: '/leaderboards',
		component: <LeaderBoards />,
	},
	{
		url: '/login',
		component: <Login />,
	},
	{
		url: '/profile',
		component: <Profile />,
	},
	{
		url: '/shop',
		component: <Shop />,
	},
	{
		url: '/playlists',
		component: <PlayLists />,
	},
];

export default routes;
