import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import routes from './routes';

function App() {
	return (
		<div>
			<Router>
				<Routes>
					{routes.map((route) => (
						<Route key={route.url} path={route.url} element={route.component} />
					))}
				</Routes>
			</Router>
		</div>
	);
}

export default App;
